import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avatar/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';
import { connect } from "react-redux";
import routes from "../../../utils/route";
import { translate } from "../../../utils/translate";
import { getProfileDetail } from "../../../services/ApiService";
import Swal from "sweetalert2";
import moment from "moment";
import { QrScanner } from '@yudiel/react-qr-scanner';
import Modal from 'react-modal';

var Url = require('url-parse');

const Header = (props) => {

	const [header, setHeader] = useState("");
	const [path, setPath] = useState("");
	const history = useHistory();

	useEffect(() => {
		let currentRoute = [];
		let pathname = window.location.pathname.slice(1);
		currentRoute = routes.filter(i => pathname.match(i.regex));

		if (currentRoute.length > 0) {
			setHeader(translate(props.lang, currentRoute[0].name));
		}


		history.listen((location) => {
			let pathname = window.location.pathname.slice(1);
			setPath(location.pathname);
			currentRoute = routes.filter(i => pathname.match(i.regex));
			if (currentRoute.length > 0) {
				setHeader(translate(props.lang, currentRoute[0].name));
			}
		})
	}, [history, props.lang]);

	useEffect(() => {
		// calling empty useEffect to trigger UI refresh
		getProfile();

	}, [header])

	useEffect(() => {
	}, [])

	const [profileData, setProfileData] = useState({});

	async function getProfile() {
		let res = await getProfileDetail();

		if (res?.status === 'success') {
			setProfileData(res.data);

			if (res.data.status == 'Expired') {
				popupAlert();
			} else if (!res.data.is_updated_profile) {
				merchantUpdateAlert();
			}
		}
	}

	const merchantUpdateAlert = () => {
		const ignore_route_arr = ["/merchant_setting"];
		if (ignore_route_arr.includes(history.location.pathname)) {
			return false;
		}
		Swal.fire({
			title: translate(props.lang, "alert"),
			text: translate(props.lang, "alertPleaseUpdateTheLatestMerchantDetail"),
			// showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: translate(props.lang, "goNow"),
			// denyButtonText: 'No',
			customClass: {
				actions: 'my-actions',
				cancelButton: 'order-1 right-gap',
				confirmButton: 'order-2',
				denyButton: 'order-3',
			}
		}).then(async (result) => {
			if (result.isConfirmed) {
				history.push('/merchant_setting');
			}
			if (result.isDismissed) {
				// popupAlert();
			}
		})
	}

	const popupAlert = () => {
		const ignore_route_arr = ["/settings/subscription", "/custody_list", "/order/payment", "/order/status"];
		if (ignore_route_arr.includes(history.location.pathname)) {
			return false;
		}
		Swal.fire({
			title: translate(props.lang, "alert"),
			text: translate(props.lang, "alertExpiredMessage"),
			// showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: translate(props.lang, "goNow"),
			// denyButtonText: 'No',
			customClass: {
				actions: 'my-actions',
				cancelButton: 'order-1 right-gap',
				confirmButton: 'order-2',
				denyButton: 'order-3',
			}
		}).then(async (result) => {
			if (result.isConfirmed) {
				history.push('/settings/subscription');
			}
			if (result.isDismissed) {
				popupAlert();
			}
		})
	}

	const previewStyle = {
		height: 240,
		width: 320,
	}

	const [isModal5Open, setIsModal5Open] = useState(false);

	const openModal5 = () => {
		setIsModal5Open(true);
	};

	const closeModal5 = () => {
		setIsModal5Open(false);
	};

	const customStyles = {
		content: {
			transform: 'translate(0, 0%)',
		},
	};

	const submitScan = async (result) => {
		if (result) {
			const current_location = window.location;
			const url_query = new Url(result);
			if (current_location.hostname == url_query.hostname) {
				closeModal5();
				window.location.href = `${url_query.pathname}${url_query.query}`;
				// history.push(`${url_query.pathname}${url_query.query}`);
			} else {
				// alert("Invalid QR Code!");
			}
		}
	}

	const goTo = (event, name) => {
		event.preventDefault();
		
		if (name) {
			if (name == 'profile') {
				history.push(`/settings/profile`);
			}
		}
	};
	
	return (
		<>
			<div className="header">
				<div className="header-content">
					<nav className="navbar navbar-expand">
						<div className="collapse navbar-collapse justify-content-between header-right-container">
							<div className="header-left">
								<div
									className="dashboard_bar"
									style={{ textTransform: "capitalize" }}
								>
									{header}
								</div>

								<div className="profile-div text-white mt-2">
									{profileData.package_name} | {parseFloat(profileData.wallet_balance).toFixed(2)}
									<br />
									{translate(props.lang, "lastPaymentDate")}: {(profileData.last_payment_date) ? moment(profileData.last_payment_date).format("YYYY-MM-DD") : "-"}
								</div>
							</div>
							<ul className="navbar-nav header-right main-notification">
								<Dropdown as="li" className="nav-item dropdown header-profile">
									<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer ps-0">
										<a href="#" onClick={(e) => openModal5()}>
											<i className="fas fa-qrcode" style={{ fontSize: "25px" }}></i>
										</a>
										{/* <img src={profile} width={20} alt="" /> */}
										<div className="header-info ms-3">
											<span>{profileData.display_name}</span>
											<small>{profileData.name}</small>
										</div>
									</Dropdown.Toggle>

									<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
										<Dropdown.Item className="ai-icon" onClick={(e) => { goTo(e, 'profile') }}>
											{/* <Link to={`/setting/profile/edit/${profileData.id}`} className="dropdown-item ai-icon"> */}
											<svg
												id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary"
												width={18} height={18} viewBox="0 0 24 24" fill="none"
												stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
											>
												<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
												<circle cx={12} cy={7} r={4} />
											</svg>
											<span className="ms-2">Profile </span>
											{/* </Link> */}
										</Dropdown.Item>
										{/* <Link to="/email-inbox" className="dropdown-item ai-icon">
										<svg
											id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width={18}
											height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2}
											strokeLinecap="round" strokeLinejoin="round"
										>
											<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
											<polyline points="22,6 12,13 2,6" />
										</svg>
										<span className="ms-2">Inbox </span>
									</Link> */}
										<LogoutPage />
									</Dropdown.Menu>
								</Dropdown>
							</ul>
						</div>
					</nav>
				</div>
			</div>
			<Modal
				isOpen={isModal5Open}
				onRequestClose={closeModal5}
				contentLabel="Modal 5"
				style={customStyles}
				className={"modal-dialog"}
			>
				<div className="modal-content">
					<div className="modal-body">
						<div className="">
							<div className="position-absolute" style={{ right: "10px", top: "10px", zIndex: "9" }}>
								<a href="#" onClick={() => closeModal5()}><i className="fas fa-times" style={{ fontSize: "20px" }}></i></a>
							</div>
							<QrScanner
								scanDelay={3000}
								containerStyle={{ width: "100%", height: "100%" }}
								onDecode={(result) => submitScan(result)}
								onError={(error) => console.log(error?.message)}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

const mapStateToProps = state => {
	const { auth, i18n } = state;
	return {
		lang: i18n.lang,
		auth: auth,
	}
}

export default connect(mapStateToProps)(Header);
